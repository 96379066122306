exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-dialog-contact-tsx": () => import("./../../../src/pages/dialog-contact.tsx" /* webpackChunkName: "component---src-pages-dialog-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-methodology-index-tsx": () => import("./../../../src/pages/methodology/index.tsx" /* webpackChunkName: "component---src-pages-methodology-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-products-consumer-tsx": () => import("./../../../src/pages/products/consumer.tsx" /* webpackChunkName: "component---src-pages-products-consumer-tsx" */),
  "component---src-pages-products-crypto-tsx": () => import("./../../../src/pages/products/crypto.tsx" /* webpackChunkName: "component---src-pages-products-crypto-tsx" */),
  "component---src-pages-products-gambling-tsx": () => import("./../../../src/pages/products/gambling.tsx" /* webpackChunkName: "component---src-pages-products-gambling-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-products-streaming-tsx": () => import("./../../../src/pages/products/streaming.tsx" /* webpackChunkName: "component---src-pages-products-streaming-tsx" */),
  "component---src-pages-recruitments-index-tsx": () => import("./../../../src/pages/recruitments/index.tsx" /* webpackChunkName: "component---src-pages-recruitments-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

